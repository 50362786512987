'use client'

import Link from 'next/link'

import { Button, Flex } from '@/components'
import { NavHeader } from '@/components/layout/NavHeader'
import { isPrd } from '@/env'

import { useClientTranslation } from './i18n/client'

export default function Error({ error }: { error: Error }) {
  const { t } = useClientTranslation(['common'])

  if (isPrd) {
    console.log({ error })
  }

  return (
    <html>
      <body>
        <NavHeader showHoverMenu={false} />

        <Flex align="center" className="h-screen" direction="column" gap="8" justify="center">
          <h2 className="text-8xl">500</h2>

          <p className="text-center text-4xl">
            所属会社のシステム管理者へ問い合わせてください
            <br />
            Please contact the system administrator of your company
          </p>

          <Button asChild>
            <Link href="/">{t('buttons.returnToHome')}</Link>
          </Button>
        </Flex>
      </body>
    </html>
  )
}
